.eMed_filterHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#eMed_filterBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#eMed_Dialog_CloseButton {
  cursor: pointer;
}
.eMed_filterTabs {
  display: flex;
  flex-direction: row;
  width: 35vw;
  height: 50vh;
}
.eMed_filterDosageType {
  width: 20vw;
  margin-top: 20px;
}
.eMed_filterBatchNo {
  margin-top: 20px;
}
.eMed_filterBatchNo :hover {
  visibility: visible;
}
.eMed_filterBatchNobox {
  width: 20vw;
}
.eMed_filterquantityFiled {
  width: 10vw;
  display: flex;
  flex-direction: row;
}
#eMed_filterquantityLBtn {
  margin-left: -12px;
  border: 1px solid rgb(201, 197, 197);
}
#eMed_filterquantityTextbox {
  text-align: center;
}
#eMed_filterquantityRBtn {
  margin-right: -12px;
  border: 1px solid rgb(201, 197, 197);
}
.eMed_filterQuantity {
  width: 20vw;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#eMed_filterHelperText {
  color: red;
  text-align: center;
}
