.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Wrapper {
    border-radius: 1vw;
    box-shadow: 0px 2px 3px #00000029;
    opacity: 1;
    margin-left: 1vw;
}

.Body_container {
    width: 7vw;
    height: 6vw;
    text-align: center;
}

#eMed_PatientType_Avatar {
    color: 'white';
    width: 3.5vw;
    height: 3.5vw;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1
}

#eMed_PatientType_icon {
    width: 2.5vw;
    height: 2vw;
}

.icon_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -2.2vw;
}

.Patient_Tag {
    margin-top: -1.8vw;
    color: white;
    font-size: .85vw;
}

.Patient_Count {
    font-size: 2vw;
    color: white;
}