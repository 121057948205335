#eMed_tbl_paymentcard {
    margin-right: 2vw;
}

.eMed_amount_column {
    min-width: 13vw
}


#eMed_year_filter {
    width: 7vw;
}