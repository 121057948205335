.eMed_sticky_col2_head {
    position: sticky;
    left: 4.5vw;
    min-width: 11vw
}

#eMed_sticky_col3_head {
    position: sticky;
    left: 15.5vw;
    min-width: 9vw;
}

#eMed_sticky_col4_head {
    position: sticky;
    left: 24.5vw;
    min-width: 12.5vw;
}

#eMed_sticky_col2_body {
    position: sticky;
    left: 4.5vw;
    min-width: 11vw;
}

#eMed_sticky_col3_body {
    position: sticky;
    left: 15.5vw;
    min-width: 9vw;
}

#eMed_sticky_col4_body {
    position: sticky;
    left: 24.5vw;
}

#eMed_tbl_searchicon {
    margin-left: 0;
}

.eMed_tbl_Quantity_col {

    min-width: 10vw
}

.eMed_Expiry_date_col {
    min-width: 10vw;
}

.eMed_Dosage_type_col {
    min-width: 10vw;
}