#eMed_selectDate {
    font-size: 1.2vw;
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-left: auto;
}

.Date {
  margin-left: 2vw;
}

.eMed_grid_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, min-content);
    grid-gap: 2vw;
    font-size: .85vw;
    padding-top: 5px;
    padding: 1vw;
}

.row {
  width: 85px;
  margin-left: 20px;
  margin-top: 5px;
}

.eMed_grid {
    display: flex;
    flex-direction: column;
    height: 14vw;
}

.grid-1 {
  grid-column: 1/3;
}

.grid-2 {
  grid-column: 3/4;
}

.grid-3 {
  grid-row: 1;
  grid-column: 4/5;
}

.grid-4 {
    grid-row: 2;
    grid-column: 1/2;
    width: 21vw;
}

.grid-5 {
  grid-row: 2;
  grid-column: 2/4;
}

.grid-6 {
    grid-row: 2;
    grid-column: 4/5;
    width: 24vw;
}

#eMed_ZoomIcon{
    font-size: 1.8vw;
}

.eMed_card{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    height: 15vw;
}
.eMed_card::-webkit-scrollbar {
    display: none;
}

.eMed_PatientType_Component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 43vw;
    height: 32vw;
}
.eMed_PieChart_Component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#eMed_StatusCard_Component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 2460px) {
  #eMed_selectDate {
    padding-top: 2vw;
  }
}
.eMed_confirm {
  width: 25vw;
  display: flex;
  justify-content: space-between;
}
.eMed_notify {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}
.eMed_dilboxAnimations {
  width: 5vw;
  height: 10vh;
}
