#eMed_vendor_stickycelltwo {
  position: sticky;
  left: 9vw;
}
#eMed_vendor_stickyHeadtwo {
  position: sticky;
  left: 9vw;
}
.eMed_vendor_tblandcard {
  display: flex;
  align-items: center;
}
