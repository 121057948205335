@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-size: 1vw;
}

.eMed_page_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#eMed_table_name {
  margin-left: 2vw;
  font-size: 1.3vw;
}

#eMed_tbl_paymentcard {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#eMed_name_totalcard {
  display: flex;
  align-items: center;
}

#eMed_totalcard {
  cursor: pointer;
  margin-left: 3.5vw;
}

#eMed_tbl_filters {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}

#eMed_tbl_datefilter {
  margin-right: 0.5vw;
  width: 12vw;
}

#eMed_tbl_searchbox {
  margin-left: 0.5vw;
  width: 13vw;
}

#eMed_tbl_searchicon {
  margin-left: 1vw;
}

.eMed_tbl_card {
  margin-top: 0.8vw;
}

.eMed_tbl_container {
  height: 55vh;
}

.eMed_sticky_start {
  position: sticky;
  left: 0;
}

.eMed_sticky_startbody {
  position: sticky;
  left: 0;
  background-color: white;
}

.eMed_sticky_end {
  position: sticky;
  right: 0;
}

.eMed_sticky_endbody {
  position: sticky;
  right: 0;
  background-color: white;
}

.eMed_sortable_name {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.eMed_sortable_number {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.eMed_sortable_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#eMed_sorting_icon {
  font-size: 1vw;
  margin-top: 0.2vw;
  cursor: pointer;
  margin-left: 0.2vw;
}

#eMed_tbl_headtext {
  font-size: 1.1vw;
  display: flex;
  align-items: center;
}

#eMed_tbl_bodytext {
  font-size: 1vw;
}

#eMed_nonsticky_head {
  z-index: 0;
}

.eMed_tbl_headwidth_small {
  min-width: 9vw;
}

.eMed_tbl_headwidth_large {
  min-width: 14vw;
}

.eMed_tbl_headwidth_extralarge {
  min-width: 16vw;
}

#eMed_icons:hover {
  transform: scale(1.2);
}

#eMed_icons {
  font-size: 1.5vw;
}

#eMed_Norecords {
  font-size: 1.5vw;
  display: flex;
  position: absolute;
  top: 55%;
  left: 43%;
}

#eMed_tbl_pagination {
  display: flex;
  float: right;
  font-size: 1vw;
}

#eMed_form_helpertext {
  font-size: 0.7vw;
  height: 1.2vw;
}

.eMed_page_top_div {
  border: 0 0 3px 3px;
  border-radius: 0 0 50px 50px;
  border-color: white;
  border-style: solid;
  box-shadow: 0px 5px 8px #00000029;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eMed_page_top_div_icons {
  display: flex;
  justify-content: space-around;
}

#eMed_Icons_Avatar {
  width: 2.5vw;
  height: 2.5vw;
  box-shadow: 0px 3px 6px #00000029;
}

#eMed_actions_column_body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#eMed_totalcard {
  display: flex;
  flex-direction: row;
}

#eMed_tbl_yearandmonth {
  width: 8vw;
}

#eMed_year_filter {
  width: 7vw;
}

#eMed_clickable_tbl_row {
  cursor: pointer;
}

.eMed_splitup_container {
  height: 23vw;
}

.eMed_splitup_card {
  height: 28vw;
  margin-top: 1vw;
}

::-webkit-scrollbar {
  width: 0.2vw;
  height: 0.2vw;
}

::-webkit-scrollbar-thumb {
  background: rgb(134, 133, 133);
  border-radius: 100vw;
  visibility: visible;
  height: 1vw;
  width: 10vw;
}