.eMed_Page_Header {
  border: 0 0 3px 3px;
  border-radius: 0 0 60px 60px;
  border-color: transparent;
  border-style: solid;
  box-shadow: 0px 5px 8px #00000029;
  display: flex;
  justify-content: space-between;
  margin-left: -1vw;
  margin-right: -1vw;
  margin-top: -0.2vw;
}

#eMed_Tablist {
  margin-left: 5vw;
}

.eMed_PageHeader_icons_div {
  display: flex;
  justify-content: space-around;
  margin-right: 2vw;
}

#eMed_IconAvatar {
  margin-top: 1vh;
  margin-right: 2vw;
  margin-bottom: 1vh;
  width: 2.5vw;
  height: 2.5vw;
  cursor: pointer;
}

#eMed_Header_Icons {
  font-size: 1.5vw;
}

#eMed_Tab_items {
  font-size: 1vw;
}

.eMed_Tab_Box {
  height: 70vh;
  overflow: scroll;
}

.css-13xfq8m-MuiTabPanel-root {
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
