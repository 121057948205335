.emed_formContainer {
  margin-left: 20px;
}
.eMed_formTextBoxone {
  width: 80vw;
  display: flex;
  justify-content: space-between;
}
.eMed_formTextBoxtwo {
  width: 59vw;
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}
#eMed_quantityLBtn {
  margin-left: -12px;
  border: 1px solid rgb(201, 197, 197);
  border-radius: 5px;
}
#eMed_quantityRBtn {
  margin-right: -12px;
  border: 1px solid rgb(201, 197, 197);
  border-radius: 5px;
}
.eMed_reasonTextBox {
  width: 30vw;
}
.eMed_reasonBtn {
  width: 80vw;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
#eMed_reasonRadiobtn {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
}
.eMed_priceBox {
  width: 16.75vw;
}
.eMed_quantity {
  width: 16.75vw;
  display: flex;
  justify-content: center;
}
.eMed_quantityFiled {
  width: 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#eMed_quantityTextbox {
  text-align: center;
}
.eMed_reasonSection {
  width: 40vw;
}
