.eMed_history_TablePaper {
  height: 32vw;
}

#eMed_History_TableContainer {
  height: 29.2vw;
}

#eMed_history_TipClose {
  font-size: 1vw;
}

#eMed_History_Header {
  text-align: center;
  font-size: 1.2vw;
}

.eMed_History_UpdateDate {
  width: 20vw;
}

#eMed_History_BodyCell {
  text-align: center;
  font-size: 1vw;
}

.eMed_History_Lastdate {
  font-size: 1vw;
}

.eMed_History_Time {
  font-size: 0.85vw;
}