#eMed_sticky_col2_head{
    position: sticky;
    left:35px;
}
#eMed_sticky_col2_body{
    position: sticky;
    left: 35px;
}

.eMed_tbl_patient_name{
    min-width: 12vw;
}
#eMed_tbl_searchicon {
    margin-left: 0;
}