.eMed_Paymentcard_Container {
    display: flex;
    flex-direction: row;
    margin-left: .8vw;
    height: 3.4vw;
}

.eMed_Paymentcard_Wrapper {
    display: flex;
    padding: .2vw;
}

.eMed_PaymentCard_Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3vw;
    height: 3vw;
    border-radius: .2vw;
}

#eMed_PaymentCard_IconButton {
    color: aliceblue;
}

.eMed_Paymentcard_Right {
    padding: .2vw;
    width: 6vw;
    margin-left: .2vw;
}

.eMed_PaymentCard_Name {
    font-size: .8vw;
}

.eMed_PaymentCard_Amount {
    font-size: .75vw;
    font-weight: bolder;
}