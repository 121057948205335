#eMed_purReturnstickyBodyTwo {
  position: sticky;
  left: 9vw;
}
#eMed_purReturnsickyHeadtwo {
  position: sticky;
  left: 9vw;
}
.eMed_purreturn_tblandcard {
  display: flex;
  align-items: center;
}
