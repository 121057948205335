.eMed_Dialog_Paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  height: 40vw;
  padding: 0.5vw;
}
.eMed_Dialog_Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5vw;
}
.eMed_Dialog_Title {
  margin-left: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3vw;
}
#eMed_Dialog_CloseButton {
  font-size: 2vw;
}
#eMed_Dialog_Component {
  margin-top: 0.5vw;
}
