#eMed_sticky_col2_head {
    position: sticky;
    left: 47px;
}

#eMed_sticky_col2_body {
    position: sticky;
    left: 47px;
}

#eMed_tbl_headtext {
    font-size: 1vw;
}

.emed_Billno_head {
    min-width: 11vw
}