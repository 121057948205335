#eMed_PatientTagContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center
}
#eMed_PatientTagWrapper{
    width: 11vw;
    margin-top:.3vw;
}
.eMed_Tag{
    padding: 0.5vw;
    width: 2.8vw;
    height: 2.5vw;
    border-radius: .4vw;
    margin-left: 1vw;
}
#eMed_TagIcon{
    width: 1.8vw;
    height: 1.5vw;
}
.eMed_TagBody{
    margin-left: .5vw;
}
.eMed_Tag_Name{
    font-size: .85vw;
}
.eMed_Tag_Count{
    font-size: .85vw;
}