.eMed_PatientStatus_Container{
    display: flex;
    flex-direction: row;
    text-align: center;
}
.eMed_PatientStatus_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .25vw;
    width: 4vw;
    height: 5.2vw;
    margin-left: .5vw;
}
#eMed_PatientStatus_Icon{
    width: 2.5vw;
    height: 2vw;
}
.eMed_Status_Name{
    color: white;
    font-size: .65vw;
}
.eMed_Status_Count{
    color: white;
    font-size: .95vw;
}