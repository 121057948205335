.eMed_Sticky_Col2 {
    position: sticky;
    left: 9vw;
}

.eMed_Sticky_Body2 {
    position: sticky;
    left: 9vw;
    background-color: white;
}
#emed_Calender_Cancel{
    margin-top: -20%;
    margin-left: 42%
}
#emed_Calender_Ok{
    margin-left: 73%;
    margin-top: -34%;
}
#eMed_header_helpertext{
    font-size: .7vw;
    max-height: 1.2vw;
}
#eMed_datePick_close{
    margin-left: 87%;
}