.fieldHeaderfordoctor {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  max-width: 22%;
  border: 1px solid white;
  border-radius: 10px;
}

.eMed_user {
  width: 12vw;
  height: 2vw;
}
.eMed_loginuser {
  font-size: 0.9vw;
}
.eMed_LoginType {
  font-size: 0.8vw;
}
.eMed_servicetype {
  width: 8vw;
}

.eMed_headContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eMed_golbalSearch {
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-left: 10px;
}

.emed_headerLogo {
  width: 5vw;
}

.doctorName {
  font-size: 0.8vw;
  font-weight: bold;
}

.eMed_profile {
  margin-left: 10px;
}

.Icon {
  color: white;
}

.opht {
  font-size: 0.7vw;
}

.eMed_searchbox {
  background-color: white;
  border-radius: 4px;
  max-width: 20vw;
}

.img_logo {
  width: 3vw;
}

.img_logo1 {
  height: 60%;
  width: 80%;
}

.label {
  font-size: 0.6vw;
  cursor: pointer;
}

.sublabel {
  font-size: 0.6vw;
}

.MenuDropdown {
  margin-left: 20px;
  font-size: 30px;
}

#emed_sidebarmenu {
  font-size: 12px;
}

.emed_Dialogboxwidth {
  padding: 15px;
  min-width: 600px;
  min-height: 400px;
}

.eMed_Drawer ::-webkit-scrollbar {
  display: none;
}

.eMed_AvatarMenu {
  width: 10vw;
  height: 13vh;
  float: right;
}

.eMed_searchbarIcon {
  cursor: pointer;
}

#eMed_menuHieghlight {
  margin: 10px;
  padding-left: 30px;
  border-radius: 4%;
}

.eMed_muiDrawer {
  display: flex;
  flex-grow: 1px;
  padding-top: 9vh;
}

#eMed_profileMenu {
  margin-top: 50px;
}

.eMed_menuIcon {
  margin-left: 8px;
}

.eMed_menuText {
  margin-left: -8px;
  font-size: 10px;
}

.eMed_subMenus {
  display: flex;
}

.eMed_muiModulepart {
  flex-grow: 1px;
  min-width: 94vw;
  padding-left: 10px;
  padding-right: 10px;
}

.eMed_menuItems {
  cursor: pointer;
  border-radius: 20%;
}

#eMed_side_list {
  margin-top: 0.5vw;
}

.eMed_appbar_menu {
  display: flex;
  width: 60vw;
  flex-direction: row;
  justify-content: flex-end;
}

#eMed_menu_icons {
  font-size: 2vw;
}
@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 4vw !important;
  }
}
@media (min-width: 600px) {
  .css-13r55hi {
    min-height: 4vw !important;
  }
}
