#eMed_stickycelltwo {
  position: sticky;
  left: 9vw;
}
#eMed_stickyHeadtwo {
  position: sticky;
  left: 9vw;
}
.eMed_purdetail_tblandcard {
  display: flex;
  align-items: center;
}
