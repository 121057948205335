@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

} */

.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color:white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input{
  display: flex;
  border-radius: 5px;
   width:300px;
   background-color:#d9fbff;
   flex-direction: column;  
  
} 
.login_checkbox{
  display: flex;
 justify-content: space-between;
}

.btn{
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img{
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color:white;

}
.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color: white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #d9fbff;
  flex-direction: column;
}
.eMed_login_checkbox {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-top: 7%;
}

.butt {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 7%;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
.label_link {
  color: black;
  text-decoration: none;
}
.eMed_textFiled {
  max-width: 90%;
}
.eMed_passwordTextbox {
  margin-top: 7%;
}
.eMed_visibleIcon {
  cursor: pointer;
}

.total {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  flex-wrap: nowrap;
  height: 100vh;
}

.left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex: 1 1;
  background-color: white;
}

.right {
  flex: 1 1;
  flex-wrap: nowrap;
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.login_input input {
  display: flex;
  border-radius: 5px;
  width: 300px;
  background-color: #d9fbff;
  flex-direction: column;
}
Button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 7%;
  background-color: rgb(4, 183, 177);
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo_img {
  height: 10%;
  display: flex;
  margin-top: 150px;
  background-color: white;
}
.link {
  color: white;
  text-decoration: none;
}

.eMed_dialogHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eMed_cancel {
  cursor: pointer;
}

.eMed_dialogBody {
  display: flex;
  flex-direction: column;
}

.eMed_dialogbodyContent {
  display: flex;
  justify-content: center;
}

#eMed_dlgBtn {
  display: flex;
  justify-content: center;
}

#eMed_Dialog_title {
  font-size: 1.2vw;
}
.errorpage{
  text-align: center;
  padding: 70px;
  font-size: 20px;
   
}
.soonpage{
    font-size: 20px;
    text-align: center;
    padding: 70px;
}
#eMed_selectDate {
    font-size: 1.2vw;
    display: flex;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
}

.Date {
  margin-left: 2vw;
}

.eMed_grid_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, -webkit-min-content);
    grid-template-rows: repeat(2, min-content);
    grid-gap: 2vw;
    font-size: .85vw;
    padding-top: 5px;
    padding: 1vw;
}

.row {
  width: 85px;
  margin-left: 20px;
  margin-top: 5px;
}

.eMed_grid {
    display: flex;
    flex-direction: column;
    height: 14vw;
}

.grid-1 {
  grid-column: 1/3;
}

.grid-2 {
  grid-column: 3/4;
}

.grid-3 {
  grid-row: 1;
  grid-column: 4/5;
}

.grid-4 {
    grid-row: 2;
    grid-column: 1/2;
    width: 21vw;
}

.grid-5 {
  grid-row: 2;
  grid-column: 2/4;
}

.grid-6 {
    grid-row: 2;
    grid-column: 4/5;
    width: 24vw;
}

#eMed_ZoomIcon{
    font-size: 1.8vw;
}

.eMed_card{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    height: 15vw;
}
.eMed_card::-webkit-scrollbar {
    display: none;
}

.eMed_PatientType_Component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 43vw;
    height: 32vw;
}
.eMed_PieChart_Component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
#eMed_StatusCard_Component{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 2460px) {
  #eMed_selectDate {
    padding-top: 2vw;
  }
}
.eMed_confirm {
  width: 25vw;
  display: flex;
  justify-content: space-between;
}
.eMed_notify {
  width: 35vw;
  display: flex;
  justify-content: space-between;
}
.eMed_dilboxAnimations {
  width: 5vw;
  height: 10vh;
}

.eMed_PatientStatus_Container{
    display: flex;
    flex-direction: row;
    text-align: center;
}
.eMed_PatientStatus_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: .25vw;
    width: 4vw;
    height: 5.2vw;
    margin-left: .5vw;
}
#eMed_PatientStatus_Icon{
    width: 2.5vw;
    height: 2vw;
}
.eMed_Status_Name{
    color: white;
    font-size: .65vw;
}
.eMed_Status_Count{
    color: white;
    font-size: .95vw;
}
.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Wrapper {
    border-radius: 1vw;
    box-shadow: 0px 2px 3px #00000029;
    opacity: 1;
    margin-left: 1vw;
}

.Body_container {
    width: 7vw;
    height: 6vw;
    text-align: center;
}

#eMed_PatientType_Avatar {
    color: 'white';
    width: 3.5vw;
    height: 3.5vw;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1
}

#eMed_PatientType_icon {
    width: 2.5vw;
    height: 2vw;
}

.icon_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -2.2vw;
}

.Patient_Tag {
    margin-top: -1.8vw;
    color: white;
    font-size: .85vw;
}

.Patient_Count {
    font-size: 2vw;
    color: white;
}
#eMed_PatientTagContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center
}
#eMed_PatientTagWrapper{
    width: 11vw;
    margin-top:.3vw;
}
.eMed_Tag{
    padding: 0.5vw;
    width: 2.8vw;
    height: 2.5vw;
    border-radius: .4vw;
    margin-left: 1vw;
}
#eMed_TagIcon{
    width: 1.8vw;
    height: 1.5vw;
}
.eMed_TagBody{
    margin-left: .5vw;
}
.eMed_Tag_Name{
    font-size: .85vw;
}
.eMed_Tag_Count{
    font-size: .85vw;
}
#eMed_MaxDialog_Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 4vw;
}
.eMed_selectItem {
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vw;
}
.eMed_settingsbtn {
  margin-top: 20px;
  width: 50vw;
  display: flex;
  justify-content: center;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#eMed_TableHeaderText {
    font-size: 1.2vw;
}

.eMed_Main_Card {
    margin-top: 2vh;
}

.eMed_table_Container {
    height: 55vh;
}

#eMed_Icons_Avatar {
    width: 2.5vw;
    height: 2.5vw;
    box-shadow: 3;
}

#eMed_patient_Name_Column {
    width: 18vw;
    font-size: 1vw;
    cursor: pointer;
}


#eMed_PatientName {
    font-size: 1.1vw;
    min-width: 6vw;
}

#eMed_PatientAcc {
    font-size: 1vw;
}

#eMed_PatientMobile {
    font-size: 1vw;
}

#eMed_Age_Column {
    font-size: 1vw;
    min-width: 3vw;
}

#eMed_Tag_Column {
    max-width: 1vw;
}

#eMed_VisitPurpose_Column {
    min-width: 10vw;
    font-size: 1vw;
}

#eMed_Consultant_Column {
    max-width: 5vw;
    font-size: 1vw;
}

#eMed_Profile_Div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2vw 0 2vw;

}

#eMed_Profile_Avatar {
    width: 3.5vw;
    height: 3.5vw;
}

#eMed_Date_Column {
    width: 11vw;
    font-size: 1vw;
    cursor: pointer;
}

#eMed_Status_Column {
    width: 5px;
}

#eMed_Actions_Column_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#eMed_Actions_Column {
    min-width: 5vw;
}

.eMed_Sortable_Cells_Number {
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.eMed_Sortable_Cells_String {
    display: flex;
    flex-direction: row;
    justify-content: left;
}
.eMed_Sortable_Cells_Date {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.MuiListItemText {
    font-size: 14px;
}

#eMed_Sorting_Icons {
    font-size: 1.2vw;
    margin-top: 2px;
}

#eMed_SearchIcon_div {
    margin-right: 3vw;
    display: flex;
    justify-content: end;
    margin-top: 0.7vw;
}

#eMed_Search {
    font-size: 2vw;
    margin-top: 2vh;
    margin-bottom: -1vh;
    margin-left: 1vw;
    cursor: pointer;
}
#eMed_Norecords_div{
    display: flex;
    justify-content: center;
    margin-top: 20vh;
    font-size: 1.5vw;
}
#eMed_Flex_cells{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#eMed_Sticky_HeadCell01{
    position: sticky;
    left: 0;
}
#eMed_Sticky_End{
    position: sticky;
    right: 0;
}
#eMed_Sticky_EndBody{
    position: sticky;
    right: 0;
    background-color: white;
}
#eMed_Sticky_HeadCell02{
    position: sticky;
    left: 47px;
}
#eMed_Non_Sticky_Head{
   z-index: 0;
}
#eMed_Sticky_Body01{
    position: sticky;
    left: 0;
    background-color: white;
}
#eMed_Sticky_Body02{
    position: sticky;
    left: 47px;
    background-color: white;
}

/* .MuiTablePagination-actions{
    color: aqua;
} */

/* .eMed_TableHeader_large {
    min-width: 13vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
#eMed_Table_HeadText {
    font-size: 1vw;
} */

#eMed_tbl_filters {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 1vw;
}

.eMed_sticky_colbody2 {
    position: -webkit-sticky;
    position: sticky;
    left: 47px;
    background-color: white;
}

#eMed_actions_column_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.eMed_sticky_start_COl2 {
    position: -webkit-sticky;
    position: sticky;
    left: 47px;
    background-color: white;
    min-width: 20vw;
}

#eMed_patient_details_body {
    display: flex;
    align-items: center;
    min-width: 14vw;
}

#eMed_patient_details_head {
    min-width: 14vw;
}

#eMed_patient_details_Text {
    margin-left: 1.5vw;
}

#eMed_profile_avatar {
    width: 3.5vw;
    height: 3.5vw;
}

.eMed_sticky_start_head2 {
    position: -webkit-sticky;
    position: sticky;
    left: 47px;
}

#eMed_tbl_searchbox {
    margin-left: .5vw;
    width: 13vw;
    height: 2vw;
}
.eMed_sticky_start_head2 {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}

.eMed_sticky_startbody_body2 {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
  background-color: white;
}
#eMed_sorting_icon {
  font-size: 1vw;
  margin-top: 0.2vw;
  cursor: pointer;
  margin-left: 0.2vw;
}
.eMed_sNo_cell {
  min-width: 7vw;
}

.eMed_history_TablePaper {
  height: 32vw;
}

#eMed_History_TableContainer {
  height: 29.2vw;
}

#eMed_history_TipClose {
  font-size: 1vw;
}

#eMed_History_Header {
  text-align: center;
  font-size: 1.2vw;
}

.eMed_History_UpdateDate {
  width: 20vw;
}

#eMed_History_BodyCell {
  text-align: center;
  font-size: 1vw;
}

.eMed_History_Lastdate {
  font-size: 1vw;
}

.eMed_History_Time {
  font-size: 0.85vw;
}
.eMed_Dialog_Paper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 85vw;
  height: 40vw;
  padding: 0.5vw;
}
.eMed_Dialog_Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5vw;
}
.eMed_Dialog_Title {
  margin-left: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3vw;
}
#eMed_Dialog_CloseButton {
  font-size: 2vw;
}
#eMed_Dialog_Component {
  margin-top: 0.5vw;
}

.emed_formContainer {
  margin-left: 20px;
}
.eMed_formTextBoxone {
  width: 80vw;
  display: flex;
  justify-content: space-between;
}
.eMed_formTextBoxtwo {
  width: 59vw;
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}
#eMed_quantityLBtn {
  margin-left: -12px;
  border: 1px solid rgb(201, 197, 197);
  border-radius: 5px;
}
#eMed_quantityRBtn {
  margin-right: -12px;
  border: 1px solid rgb(201, 197, 197);
  border-radius: 5px;
}
.eMed_reasonTextBox {
  width: 30vw;
}
.eMed_reasonBtn {
  width: 80vw;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
#eMed_reasonRadiobtn {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
}
.eMed_priceBox {
  width: 16.75vw;
}
.eMed_quantity {
  width: 16.75vw;
  display: flex;
  justify-content: center;
}
.eMed_quantityFiled {
  width: 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#eMed_quantityTextbox {
  text-align: center;
}
.eMed_reasonSection {
  width: 40vw;
}

.eMed_Page_Header {
  border: 0 0 3px 3px;
  border-radius: 0 0 60px 60px;
  border-color: transparent;
  border-style: solid;
  box-shadow: 0px 5px 8px #00000029;
  display: flex;
  justify-content: space-between;
  margin-left: -1vw;
  margin-right: -1vw;
  margin-top: -0.2vw;
}

#eMed_Tablist {
  margin-left: 5vw;
}

.eMed_PageHeader_icons_div {
  display: flex;
  justify-content: space-around;
  margin-right: 2vw;
}

#eMed_IconAvatar {
  margin-top: 1vh;
  margin-right: 2vw;
  margin-bottom: 1vh;
  width: 2.5vw;
  height: 2.5vw;
  cursor: pointer;
}

#eMed_Header_Icons {
  font-size: 1.5vw;
}

#eMed_Tab_items {
  font-size: 1vw;
}

.eMed_Tab_Box {
  height: 70vh;
  overflow: scroll;
}

.css-13xfq8m-MuiTabPanel-root {
  padding-left: 1vw !important;
  padding-right: 1vw !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.eMed_filterHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#eMed_filterBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#eMed_Dialog_CloseButton {
  cursor: pointer;
}
.eMed_filterTabs {
  display: flex;
  flex-direction: row;
  width: 35vw;
  height: 50vh;
}
.eMed_filterDosageType {
  width: 20vw;
  margin-top: 20px;
}
.eMed_filterBatchNo {
  margin-top: 20px;
}
.eMed_filterBatchNo :hover {
  visibility: visible;
}
.eMed_filterBatchNobox {
  width: 20vw;
}
.eMed_filterquantityFiled {
  width: 10vw;
  display: flex;
  flex-direction: row;
}
#eMed_filterquantityLBtn {
  margin-left: -12px;
  border: 1px solid rgb(201, 197, 197);
}
#eMed_filterquantityTextbox {
  text-align: center;
}
#eMed_filterquantityRBtn {
  margin-right: -12px;
  border: 1px solid rgb(201, 197, 197);
}
.eMed_filterQuantity {
  width: 20vw;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#eMed_filterHelperText {
  color: red;
  text-align: center;
}

#emed_CalenderHead {
    display: flex;
    flex-direction: row;
    width: 100px;
}

#emed_CalenderClose_Icon {
    margin-left: "40%";
    margin-right: '15px';
}

#emed_Calender_SearchIcon {
    margin-left: 80%;
    margin-top: -25%;
}

#emed_Calender_TodayButton {
    margin-top: -18%;
    margin-left: 65%
}
#eMed_stickyBodyTwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
#eMed_sickyHeadtwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
.eMed_puroverview_tblandcard {
  display: flex;
  align-items: center;
}

.eMed_Paymentcard_Container {
    display: flex;
    flex-direction: row;
    margin-left: .8vw;
    height: 3.4vw;
}

.eMed_Paymentcard_Wrapper {
    display: flex;
    padding: .2vw;
}

.eMed_PaymentCard_Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3vw;
    height: 3vw;
    border-radius: .2vw;
}

#eMed_PaymentCard_IconButton {
    color: aliceblue;
}

.eMed_Paymentcard_Right {
    padding: .2vw;
    width: 6vw;
    margin-left: .2vw;
}

.eMed_PaymentCard_Name {
    font-size: .8vw;
}

.eMed_PaymentCard_Amount {
    font-size: .75vw;
    font-weight: bolder;
}
#emed_CalenderHead {
    display: flex;
    flex-direction: row;
    width: 100px;
}

#emed_CalenderClose_Icon {
    margin-left: "40%";
    margin-right: '15px';
}

#emed_Calender_SearchIcon {
    margin-left: 80%;
    margin-top: -25%;
}

#emed_Calender_TodayButton {
    margin-top: -18%;
    margin-left: 65%
}
#eMed_stickycelltwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
#eMed_stickyHeadtwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
.eMed_purdetail_tblandcard {
  display: flex;
  align-items: center;
}

#eMed_purReturnstickyBodyTwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
#eMed_purReturnsickyHeadtwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
.eMed_purreturn_tblandcard {
  display: flex;
  align-items: center;
}

#eMed_invoice_stickycelltwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
#eMed_invoice_stickyHeadtwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
.eMed_invoiceadj_tblandcard {
  display: flex;
  align-items: center;
}

#eMed_vendor_stickycelltwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
#eMed_vendor_stickyHeadtwo {
  position: -webkit-sticky;
  position: sticky;
  left: 9vw;
}
.eMed_vendor_tblandcard {
  display: flex;
  align-items: center;
}

#eMed_sticky_col2_head{
    position: -webkit-sticky;
    position: sticky;
    left:35px;
}
#eMed_sticky_col2_body{
    position: -webkit-sticky;
    position: sticky;
    left: 35px;
}

.eMed_tbl_patient_name{
    min-width: 12vw;
}
#eMed_tbl_searchicon {
    margin-left: 0;
}
#eMed_sticky_col2_head {
    position: -webkit-sticky;
    position: sticky;
    left: 47px;
}

#eMed_sticky_col2_body {
    position: -webkit-sticky;
    position: sticky;
    left: 47px;
}

#eMed_tbl_headtext {
    font-size: 1vw;
}

.emed_Billno_head {
    min-width: 11vw
}
.eMed_amount_column {
    min-width: 15vw
}

#eMed_year_filter {
    width: 7vw;
}
.eMed_sticky_col2_head {
    position: -webkit-sticky;
    position: sticky;
    left: 4.5vw;
    min-width: 11vw
}

#eMed_sticky_col3_head {
    position: -webkit-sticky;
    position: sticky;
    left: 15.5vw;
    min-width: 9vw;
}

#eMed_sticky_col4_head {
    position: -webkit-sticky;
    position: sticky;
    left: 24.5vw;
    min-width: 12.5vw;
}

#eMed_sticky_col2_body {
    position: -webkit-sticky;
    position: sticky;
    left: 4.5vw;
    min-width: 11vw;
}

#eMed_sticky_col3_body {
    position: -webkit-sticky;
    position: sticky;
    left: 15.5vw;
    min-width: 9vw;
}

#eMed_sticky_col4_body {
    position: -webkit-sticky;
    position: sticky;
    left: 24.5vw;
}

#eMed_tbl_searchicon {
    margin-left: 0;
}

.eMed_tbl_Quantity_col {

    min-width: 10vw
}

.eMed_Expiry_date_col {
    min-width: 10vw;
}

.eMed_Dosage_type_col {
    min-width: 10vw;
}
#eMed_tbl_paymentcard {
    margin-right: 2vw;
}

.eMed_amount_column {
    min-width: 13vw
}


#eMed_year_filter {
    width: 7vw;
}
#eMed_sticky_col2_head{
    position: -webkit-sticky;
    position: sticky;
    left:4.5vw;
}
.eMed_Sticky_Col2 {
    position: -webkit-sticky;
    position: sticky;
    left: 9vw;
}

.eMed_Sticky_Body2 {
    position: -webkit-sticky;
    position: sticky;
    left: 9vw;
    background-color: white;
}
#emed_Calender_Cancel{
    margin-top: -20%;
    margin-left: 42%
}
#emed_Calender_Ok{
    margin-left: 73%;
    margin-top: -34%;
}
#eMed_header_helpertext{
    font-size: .7vw;
    max-height: 1.2vw;
}
#eMed_datePick_close{
    margin-left: 87%;
}
* {
  font-size: 1vw;
}

.eMed_page_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#eMed_table_name {
  margin-left: 2vw;
  font-size: 1.3vw;
}

#eMed_tbl_paymentcard {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

#eMed_name_totalcard {
  display: flex;
  align-items: center;
}

#eMed_totalcard {
  cursor: pointer;
  margin-left: 3.5vw;
}

#eMed_tbl_filters {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}

#eMed_tbl_datefilter {
  margin-right: 0.5vw;
  width: 12vw;
}

#eMed_tbl_searchbox {
  margin-left: 0.5vw;
  width: 13vw;
}

#eMed_tbl_searchicon {
  margin-left: 1vw;
}

.eMed_tbl_card {
  margin-top: 0.8vw;
}

.eMed_tbl_container {
  height: 55vh;
}

.eMed_sticky_start {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.eMed_sticky_startbody {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
}

.eMed_sticky_end {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
}

.eMed_sticky_endbody {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  background-color: white;
}

.eMed_sortable_name {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.eMed_sortable_number {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.eMed_sortable_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#eMed_sorting_icon {
  font-size: 1vw;
  margin-top: 0.2vw;
  cursor: pointer;
  margin-left: 0.2vw;
}

#eMed_tbl_headtext {
  font-size: 1.1vw;
  display: flex;
  align-items: center;
}

#eMed_tbl_bodytext {
  font-size: 1vw;
}

#eMed_nonsticky_head {
  z-index: 0;
}

.eMed_tbl_headwidth_small {
  min-width: 9vw;
}

.eMed_tbl_headwidth_large {
  min-width: 14vw;
}

.eMed_tbl_headwidth_extralarge {
  min-width: 16vw;
}

#eMed_icons:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

#eMed_icons {
  font-size: 1.5vw;
}

#eMed_Norecords {
  font-size: 1.5vw;
  display: flex;
  position: absolute;
  top: 55%;
  left: 43%;
}

#eMed_tbl_pagination {
  display: flex;
  float: right;
  font-size: 1vw;
}

#eMed_form_helpertext {
  font-size: 0.7vw;
  height: 1.2vw;
}

.eMed_page_top_div {
  border: 0 0 3px 3px;
  border-radius: 0 0 50px 50px;
  border-color: white;
  border-style: solid;
  box-shadow: 0px 5px 8px #00000029;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eMed_page_top_div_icons {
  display: flex;
  justify-content: space-around;
}

#eMed_Icons_Avatar {
  width: 2.5vw;
  height: 2.5vw;
  box-shadow: 0px 3px 6px #00000029;
}

#eMed_actions_column_body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#eMed_totalcard {
  display: flex;
  flex-direction: row;
}

#eMed_tbl_yearandmonth {
  width: 8vw;
}

#eMed_year_filter {
  width: 7vw;
}

#eMed_clickable_tbl_row {
  cursor: pointer;
}

.eMed_splitup_container {
  height: 23vw;
}

.eMed_splitup_card {
  height: 28vw;
  margin-top: 1vw;
}

::-webkit-scrollbar {
  width: 0.2vw;
  height: 0.2vw;
}

::-webkit-scrollbar-thumb {
  background: rgb(134, 133, 133);
  border-radius: 100vw;
  visibility: visible;
  height: 1vw;
  width: 10vw;
}
.eMed_header_search_div {
    display: flex;
    flex-direction: row;
}

.eMed_header_div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_modal_card {
    position: absolute;
    top: 52%;
    left: 80%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 30vw;
    background-color: white;
    padding: .5vw;
}

#eMed_report_modalpop{
    width: 90vw;
}

#eMed-modal-header{
    padding: .5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
#eMed-modal-footer{
    display: flex;
    flex-direction: row;
    padding: .5vw;
    align-items: center;
    justify-content: center;
}
.eMed_model_body{
    display: flex;
    flex-direction: column;
    padding: 1vw;
}
#eMed_model_title{
    font-size: larger;
    margin-left: .3vw;
}
.eMed_model_dropbox{
    margin-top: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.eMed_model_formcontrol{
    width: 15vw;
}
#eMed_Table_Div{
    margin-top: 2vh;
    height: 73vh;
}
#eMed_menuList_icons{
    margin-right: 1vw;
}
.fieldHeaderfordoctor {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  max-width: 22%;
  border: 1px solid white;
  border-radius: 10px;
}

.eMed_user {
  width: 12vw;
  height: 2vw;
}
.eMed_loginuser {
  font-size: 0.9vw;
}
.eMed_LoginType {
  font-size: 0.8vw;
}
.eMed_servicetype {
  width: 8vw;
}

.eMed_headContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eMed_golbalSearch {
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  margin-left: 10px;
}

.emed_headerLogo {
  width: 5vw;
}

.doctorName {
  font-size: 0.8vw;
  font-weight: bold;
}

.eMed_profile {
  margin-left: 10px;
}

.Icon {
  color: white;
}

.opht {
  font-size: 0.7vw;
}

.eMed_searchbox {
  background-color: white;
  border-radius: 4px;
  max-width: 20vw;
}

.img_logo {
  width: 3vw;
}

.img_logo1 {
  height: 60%;
  width: 80%;
}

.label {
  font-size: 0.6vw;
  cursor: pointer;
}

.sublabel {
  font-size: 0.6vw;
}

.MenuDropdown {
  margin-left: 20px;
  font-size: 30px;
}

#emed_sidebarmenu {
  font-size: 12px;
}

.emed_Dialogboxwidth {
  padding: 15px;
  min-width: 600px;
  min-height: 400px;
}

.eMed_Drawer ::-webkit-scrollbar {
  display: none;
}

.eMed_AvatarMenu {
  width: 10vw;
  height: 13vh;
  float: right;
}

.eMed_searchbarIcon {
  cursor: pointer;
}

#eMed_menuHieghlight {
  margin: 10px;
  padding-left: 30px;
  border-radius: 4%;
}

.eMed_muiDrawer {
  display: flex;
  flex-grow: 1px;
  padding-top: 9vh;
}

#eMed_profileMenu {
  margin-top: 50px;
}

.eMed_menuIcon {
  margin-left: 8px;
}

.eMed_menuText {
  margin-left: -8px;
  font-size: 10px;
}

.eMed_subMenus {
  display: flex;
}

.eMed_muiModulepart {
  flex-grow: 1px;
  min-width: 94vw;
  padding-left: 10px;
  padding-right: 10px;
}

.eMed_menuItems {
  cursor: pointer;
  border-radius: 20%;
}

#eMed_side_list {
  margin-top: 0.5vw;
}

.eMed_appbar_menu {
  display: flex;
  width: 60vw;
  flex-direction: row;
  justify-content: flex-end;
}

#eMed_menu_icons {
  font-size: 2vw;
}
@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 4vw !important;
  }
}
@media (min-width: 600px) {
  .css-13r55hi {
    min-height: 4vw !important;
  }
}

#eMed_dropDown_formcontrol{
    margin:1vw;
    width:20vw
  }

#eMed_DropDown_Box{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 30vw;
}
#eMed_individual_div{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
#eMed_dialog_title{
    font-size: 1.3vw;
}
#eMed_dialog_text{
    font-size: 1.2vw;
}
#eMed_dialog_button_div{
    display: flex;
    justify-content: center;
    margin-bottom: 0.5vw;
    
}
#eMed_dialog_button{
    margin-right: 1vw ;
}
