#eMed_dropDown_formcontrol{
    margin:1vw;
    width:20vw
  }

#eMed_DropDown_Box{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 30vw;
}
#eMed_individual_div{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
}
#eMed_dialog_title{
    font-size: 1.3vw;
}
#eMed_dialog_text{
    font-size: 1.2vw;
}
#eMed_dialog_button_div{
    display: flex;
    justify-content: center;
    margin-bottom: 0.5vw;
    
}
#eMed_dialog_button{
    margin-right: 1vw ;
}