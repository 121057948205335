#eMed_tbl_filters {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 1vw;
}

.eMed_sticky_colbody2 {
    position: sticky;
    left: 47px;
    background-color: white;
}

#eMed_actions_column_body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.eMed_sticky_start_COl2 {
    position: sticky;
    left: 47px;
    background-color: white;
    min-width: 20vw;
}

#eMed_patient_details_body {
    display: flex;
    align-items: center;
    min-width: 14vw;
}

#eMed_patient_details_head {
    min-width: 14vw;
}

#eMed_patient_details_Text {
    margin-left: 1.5vw;
}

#eMed_profile_avatar {
    width: 3.5vw;
    height: 3.5vw;
}

.eMed_sticky_start_head2 {
    position: sticky;
    left: 47px;
}

#eMed_tbl_searchbox {
    margin-left: .5vw;
    width: 13vw;
    height: 2vw;
}