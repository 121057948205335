.eMed_sticky_start_head2 {
  position: sticky;
  left: 9vw;
}

.eMed_sticky_startbody_body2 {
  position: sticky;
  left: 9vw;
  background-color: white;
}
#eMed_sorting_icon {
  font-size: 1vw;
  margin-top: 0.2vw;
  cursor: pointer;
  margin-left: 0.2vw;
}
.eMed_sNo_cell {
  min-width: 7vw;
}
