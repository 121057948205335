.eMed_header_search_div {
    display: flex;
    flex-direction: row;
}

.eMed_header_div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.eMed_modal_card {
    position: absolute;
    top: 52%;
    left: 80%;
    transform: translate(-50%, -50%);
    width: 30vw;
    background-color: white;
    padding: .5vw;
}

#eMed_report_modalpop{
    width: 90vw;
}

#eMed-modal-header{
    padding: .5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
#eMed-modal-footer{
    display: flex;
    flex-direction: row;
    padding: .5vw;
    align-items: center;
    justify-content: center;
}
.eMed_model_body{
    display: flex;
    flex-direction: column;
    padding: 1vw;
}
#eMed_model_title{
    font-size: larger;
    margin-left: .3vw;
}
.eMed_model_dropbox{
    margin-top: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.eMed_model_formcontrol{
    width: 15vw;
}